// userSlice.js
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { calculateAge } from '../Pages/Common/helperFunctions';
import CryptoJS from 'crypto-js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PAYLOADKEY = process.env.REACT_APP_PAYLOAD_SECRET_KEY;

const cookies = new Cookies();

const initialState = {
  username: '',
  mode: '',
  string: '',
  profilePhoto: '',
  userId: '',
  token: '',
  age: '',
  city: '',
  status: '',
  firstLogin: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const { username, mode, profilePhoto, token, userId, age, city, status, firstlogin } = action.payload;
      if (username !== undefined) {
        state.username = username;
      } if (age !== undefined) {
        state.age = age;
      } if (city !== undefined) {
        state.city = city;
      }
      if (mode !== undefined) {
        const iv = CryptoJS.lib.WordArray.random(16);
        const encrypted = CryptoJS.AES.encrypt(
          JSON.stringify({ mode }),
          CryptoJS.enc.Utf8.parse(PAYLOADKEY),
          {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }
        );
        const ivString = iv.toString(CryptoJS.enc.Hex);
        state.mode = encrypted.toString();
        state.string = ivString;
      }
      if (profilePhoto !== undefined) {
        state.profilePhoto = profilePhoto;
      }
      if (userId !== undefined) {
        state.userId = userId;
      }
      if (token !== undefined) {
        state.token = token;
      }
      if (status !== undefined) {
        state.status = status;
      }

      if(firstlogin !== undefined) {
        state.firstLogin = firstlogin
      }
    },
    reset: () => initialState,
    updateFirstLogin: (state, action) => {
      state.firstLogin = action.payload;
    }
    
  },
});

export const fetchrefreshTokenData = () => async (dispatch) => {
  try {

    const url = `${API_BASE_URL}refreshtoken`;
    const response = await axios.post(url, { "refreshToken": cookies.get('refresh_token') });
    if (response.status === 200) {
      const login = response.data;
      cookies.set('refresh_token', login.refresh_token, {
        path: '/',
        httpOnly: true,
        // secure: true // Set to true if using HTTPS
      });

      if (login && login.user_ref_id) {
        let data = { username: login.user_name, mode: login.login_mode, profilePhoto: login.profile_photo, userId: login.user_ref_id, status: login.user_status && login.user_status }
        if (login.user_dob && login.user_dob !== "") {
          data.age = calculateAge(login.user_dob);
        }
        if (login.user_city && login.user_city !== "") {
          data.city = login.user_city
        }
        dispatch(updateUser(data));
      }
    }
  } catch (error) {
  //  window.location.href = `/login`;
  if(error?.response?.status == '401'){

  } else if(error?.response?.status == '403'){
    window.location.href = `/login`;
  }
    console.error('Error fetching user data:', error);
    // Handle errors as needed
  }
};

export const { updateUser, reset, updateFirstLogin } = userSlice.actions;
export default userSlice.reducer;
