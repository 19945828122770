import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { calculateAge } from '../Pages/Common/helperFunctions';
import { createContext, useState, useEffect } from "react";
import { reset, updateUser } from "../Redux/userSlice";
import swal from 'sweetalert2';
import { useDispatch } from "react-redux";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const cookies = new Cookies();
const AuthContext = createContext({});





export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [auth, setAuth] = useState();
  useEffect(() => {
    // This effect will run whenever the user state changes
    const fetchData = async () => {
      if (!auth) {
        try {
          const url = `${API_BASE_URL}refreshtoken`;
          const response = await axios.post(url, { "refreshToken": sessionStorage.getItem('refresh_token') });
          if (response.status === 200) {
            const login = response.data;
            const expirationDate = new Date();
            expirationDate.setFullYear(expirationDate.getFullYear() + 1);
            const expires = expirationDate.toUTCString();
            sessionStorage.setItem("refresh_token",login.refresh_token);
            // if(process.env.REACT_APP_BASE_URL_LOCAL){
            //   document.cookie = `refresh_token=${login.refresh_token}; path=/; domain=localhost`;
            //   document.cookie = `refresh_token=${login.refresh_token}; path=/; domain=${process.env.REACT_APP_BASE_URL_LOCAL}`;
            // }else if(process.env.REACT_APP_BASE_URL_PROD){
            //   document.cookie = `refresh_token=${login.refresh_token}; path=/; domain=${process.env.REACT_APP_BASE_URL_PROD}`;
            // }
            if (login && login.user_ref_id) {
              let data = { username: login.user_name, mode: login.user_mode, profilePhoto: login.profile_photo, token: login.token, userId: login.user_ref_id, status: login.user_status && login.user_status }
              let reduxdata = { username: login.user_name, mode: login.user_mode, profilePhoto: login.profile_photo, token: login.token, userId: login.user_ref_id, status: login.user_status && login.user_status }
              if (login.user_dob && login.user_dob !== "") {
                data.age = calculateAge(login.user_dob);
              }
              if (login.user_city && login.user_city !== "") {
                data.city = login.user_city
              }
              setAuth(data);
              dispatch(updateUser(reduxdata));
              return data;
            }
          }
        } catch (error) {
          if(error?.response?.status == '401'){
            dispatch(reset());
            localStorage.clear();
          } else if(error?.response?.status == '403'){
            swal.fire({
              text: "Access Forbidden,Please login and try again",
              icon: 'warning',
              type: 'warning',
          });
            navigate('/login')
          }
          // Handle errors as needed
        }
      }
    }
    fetchData();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;